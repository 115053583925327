@import './colors.css';

.form-input {
  .input {
    min-height: 60px;
    margin-bottom: 5px;

    > label[data-shrink='true'],
    div:focus {
      color: var(--color-grey-text);
      font-size: 25px;
      top: -5px;
    }
    > label .MuiInput-underline:after {
      border-bottom: 2px solid var(--color-grey-);
    }
  }
  .suggestion {
    color: var(--color-grey);

    .suggestion-value {
      color: var(--color-main);
      text-decoration: underline;
    }
  }
}
